.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 16px 0 0 0;
	color: var(--color-neutral);
}
.container i {
	font-size: 14px;
	width: 33px;
	text-align: right;
}
.container p {
	overflow: hidden;
	text-overflow: ellipsis;
}
.thumbnail {
	height: 72px;
	width: 72px;
	border-radius: 8px;
	object-fit: cover;
}
