.header {
    margin-bottom: 16px;
}

header div {

}
.list {
    display: grid;
    grid-template-columns: 11px auto;
    max-width: 500px;
}
.listItem {
    display: flex;
}
.listContent {
    width: 100%;
}
.listContent h1 {
    margin-left: 16px;
}
.list i {
    position: relative;
    top: 5px;
    right: 5px;
}