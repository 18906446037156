.container {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.title {
	margin: auto;
	text-align: center;
	font-size: 14px;
	margin-bottom: 20px;
}
.image {
	width: 80%;
	margin-left: 10%;
	margin-right: 10%;
}
.success {
	border: 5px solid var(--color-vivere);
	border-radius: 50%;
	width: 120px;
	height: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 55px 0;
}
.success i {
	font-size: 75px;
	color: var(--color-vivere);
}
