.container {
	width: 100%;
	text-align: center;
	position: fixed;
	bottom: 0;
	right: 0;
	background: white;
	box-shadow: -1px 2px 7px #8888889c;
}
.icon {
	color: var(--color-neutral);
}
.rateSelected {
	color: #fdb201;
}
.checkbox input[type='checkbox'] {
}
.checkbox label {
	cursor: pointer;
	padding: 4px;
	border: 1px solid var(--color-indigo);
	margin: 4px;
	border-radius: 3px;
	color: var(--color-indigo);
	font-size: 16px;
	font-weight: bold;
	display: inline-block;
}
.checkbox input[type='checkbox']:checked + label {
	background-color: #3743aa;
	color: white;
}
.button {
	margin-top: 60px;
}
