.content {
}
.content p {
    padding: 0;
    margin: 0 0 16px;
}
.content p:last-child {
    margin-bottom: 0;
}
.content ol {
    margin-left: 32px;
    margin-bottom: 16px;
}
.content ol:last-child {
    margin-bottom: 0;
}
.content ul {
    margin-left: 32px;
    margin-bottom: 16px;
}
.content ul:last-child {
    margin-bottom: 0;
}
.content li {
    margin-bottom: 8px;
}

.content button:last-child h2 {
    padding-bottom: 0;
    margin-bottom: 0;
}
.content button:last-child i {
    margin-top: 16px;
}
.dropdownTitle {
    min-height: 58px;
    justify-content: space-between;
}

.content table {
    border: 1px solid var(--color-manatee);
    border-radius: var(--border-radius);
    border-spacing: 0;
    margin-bottom: 16px;
    width: 100%;
}

.content table thead{
    text-align: left;
    background-color: var(--color-white);
}

.content table thead th:first-child  {
    border-right: 1px solid var(--color-manatee);
}

.content table th, .content table td {
    padding: 8px 0 8px 16px
;
}
.content table tbody tr:nth-child(odd) {
    background-color: var(--color-manatee);
}
.content table tr:nth-child(even) td:first-child {
    border-right: 1px solid var(--color-manatee);
}

.content table tr {
    height: 25px;
}

.content a {
    color: var(--color-indigo)
}
.scenario {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: none;
    background: none;
}
.scenario i{
    color: var(--color-neutral);
}

.button {
    padding-bottom: 64px;
}