@font-face {
	font-family: 'ibons';
	src: url('./fonts/albo-icons/ibons.eot?31p1ox');
	src: url('./fonts/albo-icons/ibons.eot?31p1ox#iefix') format('embedded-opentype'),
		url('./fonts/albo-icons/ibons.ttf?31p1ox') format('truetype'),
		url('./fonts/albo-icons/ibons.woff?31p1ox') format('woff'),
		url('./fonts/albo-icons/ibons.svg?31p1ox#ibons') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

i {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'ibons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 24px;
}

.ibon-star:before {
	content: '\e934';
}
.ibon-download:before {
	content: '\e900';
}
.ibon-addperson:before {
	content: '\e902';
}
.ibon-alias:before {
	content: '\e903';
}
.ibon-autodeposti:before {
	content: '\e904';
}
.ibon-bank:before {
	content: '\e905';
}
.ibon-birthday:before {
	content: '\e906';
}
.ibon-cake:before {
	content: '\e907';
}
.ibon-calendar:before {
	content: '\e908';
}
.ibon-camera:before {
	content: '\e909';
}
.ibon-car:before {
	content: '\e90a';
}
.ibon-cellphone:before {
	content: '\e90c';
}
.ibon-check:before {
	content: '\e90d';
}
.ibon-chevron-down:before {
	content: '\e90e';
}
.ibon-chevron-left:before {
	content: '\e90f';
}
.ibon-chevron-right:before {
	content: '\e910';
}
.ibon-chevron-up:before {
	content: '\e911';
}
.ibon-city:before {
	content: '\e912';
}
.ibon-close:before {
	content: '\e913';
}
.ibon-coupon:before {
	content: '\e914';
}
.ibon-delete:before {
	content: '\e915';
}
.ibon-edit:before {
	content: '\e916';
}
.ibon-exchange:before {
	content: '\e917';
}
.ibon-exclamation:before {
	content: '\e918';
}
.ibon-flame:before {
	content: '\e919';
}
.ibon-gallery:before {
	content: '\e91a';
}
.ibon-gender:before {
	content: '\e91b';
}
.ibon-home:before {
	content: '\e91c';
}
.ibon-id:before {
	content: '\e91d';
}
.ibon-info:before {
	content: '\e91e';
}
.ibon-lock:before {
	content: '\e91f';
}
.ibon-mailbox:before {
	content: '\e920';
}
.ibon-message:before {
	content: '\e921';
}
.ibon-null:before {
	content: '\e922';
}
.ibon-number:before {
	content: '\e923';
}
.ibon-person:before {
	content: '\e924';
}
.ibon-phone:before {
	content: '\e925';
}
.ibon-pin:before {
	content: '\e926';
}
.ibon-reports:before {
	content: '\e927';
}
.ibon-search:before {
	content: '\e928';
}
.ibon-send:before {
	content: '\e929';
}
.ibon-services:before {
	content: '\e92a';
}
.ibon-settings:before {
	content: '\e92b';
}
.ibon-share:before {
	content: '\e92c';
}
.ibon-sink:before {
	content: '\e92d';
}
.ibon-spaces:before {
	content: '\e92e';
}
.ibon-suitcase:before {
	content: '\e92f';
}
.ibon-support:before {
	content: '\e930';
}
.ibon-tag:before {
	content: '\e931';
}
.ibon-television:before {
	content: '\e932';
}
.ibon-thunder:before {
	content: '\e933';
}
.ibon-mail:before {
	content: '\e901';
}
.ibon-card:before {
	content: '\e90b';
}
.ibon-account:before {
	content: '\e935';
}
