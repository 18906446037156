.buttons {
	display: flex;
	justify-content: center;
	align-items: center;
}

.buttons img {
	padding: 0 12px;
}
.reason {
	margin-left: 5px;
	margin-bottom: 10px;
}
.information {
	padding-top: 0;
}
