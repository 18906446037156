.listCard {
	width: 95%;
	margin: auto;
}
.listCard li {
	display: inline-block;
	width: 50%;
	text-align: center;
	margin-top: 16px;
}
.listCard li a {
	text-decoration: none;
}
