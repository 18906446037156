.container {
	overflow: hidden;
	background-color: var(--color-white);
	border-bottom: 1px solid var(--color-manatee);
}
.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: var(--color-white);
}
.title i {
	margin-right: 16px;
}
