.searchContainer {
	position: sticky;
	background-color: white;
	padding: 0 16px 16px 16px;
	align-items: center;
	border-bottom: 1px solid #eef0f4;
	top: 0;
}
.searchContainer p {
	font-size: 16px;
	font-weight: bold;
	padding-left: 0;
}
.searchContainer div {
	position: relative;
	z-index: -2;
}
.searchContainer .inputSearch {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.search {
	border: 1px solid var(--color-manatee) !important;
	height: 35px;
	border-radius: 3px;
	padding-left: 35px;
	padding-right: 24px;
	width: initial !important;
	margin-top: 0 !important;
}
.icon {
	position: absolute;
	margin-left: 8px;
	color: var(--color-charcoal);
	font-size: 22px;
}
.cancel {
	height: 34px;
	border: none;
	background: none;
	color: var(--color-neutral);
	font-size: 12px;
	position: absolute;
	right: 0;
	top: 0;
	z-index: -3;
}
.cancel:focus {
	outline: none;
}
.clean {
	width: 14px;
	position: absolute;
	right: 8px;
}
