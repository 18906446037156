.dropdownTitle {
	min-height: 58px;
	justify-content: space-between;
}
.scenario {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border: none;
	background: none;
}
.scenario i{
	color: var(--color-neutral);
}

.button {
	padding-bottom: 64px;
}