.container {
	overflow: hidden;
	background-color: var(--color-white);
	border-bottom: 1px solid var(--color-manatee);
}
.container li {
	margin-left: 24px;
	list-style-type: none;
}
.removeThisClass {
	color: red;
	overflow: hidden;
	background-color: var(--color-white);
	border-bottom: 1px solid var(--color-manatee);
}
