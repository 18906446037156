.container {
	width: 160px;
	height: 128px;
	background: var(--color-white);
	border: 1px solid #eef0f4;
	border-radius: 8px;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.container p {
	font-size: 14px;
	text-align: center;
	padding: 0;
	min-height: 36px;
}

a:focus-visible {
	outline-offset: 0;
}
