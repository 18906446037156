.message {
	text-align: center;
}
.message img {
	margin-top: 81px;
	margin-bottom: 32px;
	width: 56px;
}
.message p:first-child {
	margin-top: 43px;
}
.message p {
	font-size: 16px;
}
