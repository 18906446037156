.ticketPage {
	background: var(--color-white);
	height: 100vh;
	margin: 16px;
}
.typeOfTicket {
	list-style-type: none;
	text-align: center;
	margin: 16px 0;
	background-color: var(--color-manatee);
	min-height: 34px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	position: relative;
	border-radius: 4px;
}

.typeOfTicket .switch {
	background-color: var(--color-indigo);
	height: 84%;
	width: 50%;
	position: absolute;
	left: 0;
	margin: 4px;
	border-radius: 4px;
}
.typeOfTicket li {
	z-index: 10;
}
.textWhite {
	color: var(--color-white);
}
