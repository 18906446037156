.card {
	background-color: var(--color-snow);
	border-radius: 1px solid var(--color-manatee);
	height: 76px;
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 16px;
}
.card p {
	padding: 8px 0 0 0;
}
.card button {
	text-align: initial;
	background: none;
	border: none;
	font: inherit;
}
