.inputFile {
	margin-bottom: 100px;
}
.required {
	border-bottom: 1px solid var(--color-sunset) !important;
}
.disabled {
	background: var(--color-manatee);
	border: 0;
	color: var(--color-neutral);
}
