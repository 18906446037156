.title {
	align-items: center;
	display: flex;
	flex-direction: row;
	height: 92px;
	background: var(--color-white);
	margin-left: 16px;
}
.titleIcon {
	width: calc(100% - 48px);
	text-align: center;
}

.titleIcon i {
	font-size: 32px;
}
