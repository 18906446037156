.agent {
	border: 1px solid var(--color-manatee);
	border-radius: 5px;
	width: 80%;
	padding: 8px;
	position: relative;
}
.agent:before,
.agent:after {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
}

.agent:before {
	left: -9px;
	bottom: -1px;
	border: 8px solid;
	border-color: transparent transparent var(--color-manatee) transparent;
}
.agent:after {
	left: -7px;
	bottom: 0;
	border: 8px solid;
	border-color: transparent transparent #fff transparent;
}
.customer {
	background-color: var(--color-manatee);
	margin-left: auto;
	margin-right: 0;
	width: 80%;
	border-radius: 5px;
	padding: 8px;
	position: relative;
}
.customer:after {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: auto;
	right: -8px;
	top: auto;
	bottom: 0;
	border: 8px solid;
	border-color: transparent transparent var(--color-manatee) transparent;
	z-index: -10;
}
.time {
	color: var(--color-neutral);
	font-size: 12px;
	margin-top: 8px;
	display: block;
}
.container {
	padding-bottom: 127px;
}

.thumbnail {
	height: 160px;
	width: 100px;
	object-fit: cover;
	background: var(--color-manatee);
	border-radius: 4px;
	margin-left: 9px;
	padding: 5px;
}
.containerImgCustomer {
	margin-left: auto;
	margin-right: 0;
	width: 80%;
	text-align: right;
}
.albo {
	font-size: 12px;
	color: var(--color-neutral);
}
.required {
	border-bottom: 1px solid var(--color-sunset) !important;
}
